import React, { useState } from 'react';
import ReactDOM from 'react-dom';

import './styles/custom.scss';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MatchSimulatorApp from './match-app';

const App = () => (
<Container fluid>
	<Row>
		<Col className="mb-2 pe-5 pe-md-0 tennis-secondary tennis-header">
			<h1>Tennis Match Simulator</h1>
			<h4 className="tagline"><i>"What if they played 100 matches?"</i></h4>
		</Col>
	</Row>
	<MatchSimulatorApp />
	<Row>
		<Col className="mt-2 tennis-secondary tennis-footer">
			<Row>
				<Col xs={6}>
					<p>&copy; Nomasi Oy</p>
				</Col>
				<Col xs={6}>
					<p className="text-end">All feedback is welcome at <a href="mailto:tms@nomasi.com">tms@nomasi.com</a></p>	
				</Col>
			</Row>
		</Col>
	</Row>
</Container>
);

const mountNode = document.getElementById('root');
ReactDOM.render(<App />, mountNode);
