import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { MatchInputs, RuleInputs } from './match-app-elements';
import MatchResults from './match-results';

export function MatchSimulatorApp() {
	
	const matchLabels = {
		firstServePercentage: 'First serve percentage',
		winningOnFirstServe: 'Winning on first serve',
		secondServePercentage: 'Second serve percentage',
		winningOnSecondServe: 'Winning on second serve',
		runDetailLabel: 'Simulation mode',
	}

	const matchDefaults = {
		firstServePercentage: [75,75],
		winningOnFirstServe: [75,75],
		secondServePercentage: [100,80],
		winningOnSecondServe: [50,50],
		runDetail: { 0: "One", 1: "100", 2: "Pro" },
		runDetailDetails: { 0: "Run one match, slowly", 1: "Run 100 matches, fast", 2: "Run 100000 matches, super fast" }
	}

	const ruleLabels = {
		rulePreset: 'Rule set',
		setsNeededForWin: 'Sets needed for win',
		gamesNeededForSet: 'Games needed for set',
		noAD: 'No AD',
		normalTiebreakToPoints: 'Normal tiebreak to points',
		lastSetMatchTiebreak: 'Last set tb to points',
		lastSetMatchTiebreakStartingAtGames: 'Last set tb starts at games',
	}

	const ruleShorthands = {
		preset: 'preset',
		setsNeededForWin: 'snfw',
		gamesNeededForSet: 'gnfs',
		noAD: 'noad',
		normalTiebreakToPoints: 'ntbtp',
		lastSetMatchTiebreak: 'lsmtb',
		lastSetMatchTiebreakStartingAtGames: 'lsmtbsag',		
	}

	interface RulePreset {
		label: string;
		rules: {
		  setsNeededForWin: number;
		  gamesNeededForSet: number;
		  noAD: boolean;
		  normalTiebreakToPoints: number;
		  lastSetMatchTiebreak: number;
		  lastSetMatchTiebreakStartingAtGames: number;
		};
	  }
	  
	interface RulePresets {
		[key: string]: RulePreset;
	}	

	const rulePresets = {
		ATP_Singles: { 
			label: "ATP Tour: Singles",
			rules: {
				setsNeededForWin: 2,
				gamesNeededForSet: 6,
				noAD: false,
				normalTiebreakToPoints: 7,
				lastSetMatchTiebreak: 7,
				lastSetMatchTiebreakStartingAtGames: 6
			}
		},
		ATP_Doubles: { 
			label: "ATP Tour: Doubles",
			rules: {
				setsNeededForWin: 2,
				gamesNeededForSet: 6,
				noAD: true,
				normalTiebreakToPoints: 7,
				lastSetMatchTiebreak: 10,
				lastSetMatchTiebreakStartingAtGames: 0
			}
		},
		GS_Singles_Men: { 
			label: "Grand Slams: Singles Men",
			rules: {
				setsNeededForWin: 3,
				gamesNeededForSet: 6,
				noAD: false,
				normalTiebreakToPoints: 7,
				lastSetMatchTiebreak: 10,
				lastSetMatchTiebreakStartingAtGames: 6
			}
		},
		GS_Doubles: { 
			label: "Grand Slams: Doubles",
			rules: {
				setsNeededForWin: 2,
				gamesNeededForSet: 6,
				noAD: false,
				normalTiebreakToPoints: 7,
				lastSetMatchTiebreak: 10,
				lastSetMatchTiebreakStartingAtGames: 6
			}
		},
	}

	function loadPreset(presetName) {
		if (typeof rulePresets[presetName] === 'undefined') return false;
		Object.entries(rulePresets[presetName].rules).map(([presetKey, value]) => {
			const inputKey = ruleShorthands[presetKey];
			setInputs(values => ({...values, [inputKey]: value}));
		});
	}

	// init state variables
	const [isRunning, setRunning] = useState(false);
	// init inputs with ruleset
	const [inputs, setInputs] = useState({
		preset: "ATP_Singles",
		snfw: rulePresets.ATP_Singles.rules.setsNeededForWin,
		gnfs: rulePresets.ATP_Singles.rules.gamesNeededForSet,
		noad: rulePresets.ATP_Singles.rules.noAD,
		ntbtp: rulePresets.ATP_Singles.rules.normalTiebreakToPoints,
		lsmtb: rulePresets.ATP_Singles.rules.lastSetMatchTiebreak,
		lsmtbsag: rulePresets.ATP_Singles.rules.lastSetMatchTiebreakStartingAtGames,
		rc: 1
	});
	
	function handleChange(event) {
		const name = event.target.name;
		var value = event.target.value;
		switch(name) {
			case 'noad':
				value = (value == 1);
				break;
		}
		setInputs(values => ({...values, [name]: value, preset: 'custom'}));
	}

	function handleOddsChange(event) {
		const name = event.target.name;
		const value = event.target.value;
		setInputs(values => ({...values, [name]: value}));
	}	

	function handleSliderChange(value, name) {
		setInputs(values => ({...values, [name]: value}));
	}

	function handlePresetChange(event) {
		const name = event.target.name;
		var value = event.target.value;
		loadPreset(value);
		setInputs(values => ({...values, [name]: value}));
	}

	function startSimulation() {
		setRunning(true);
	}

	function resetSimulation() {
		setRunning(false);
	}

	return (
		<>
			<Form>
				<Row>
					<Col xs={12} md={8} className="me-md-2 tennis-primary tennis-odds">
						<h2>Odds:</h2>
						<MatchInputs
							onSubmit={!isRunning ? startSimulation : resetSimulation}
							onChange={handleOddsChange}
							onSliderChange={handleSliderChange}
							submitLabel={isRunning ? "Reset simulation" : "Start simulation"}
							matchLabels={matchLabels}
							matchDefaults={matchDefaults}
							inputs={inputs}
							isRunning={isRunning}
						/>
					</Col>
					<Col className="mt-2 mt-md-0 tennis-primary">
						<h2>Rules:</h2>
						<RuleInputs
							onChange={handleChange}
							onPresetChange={handlePresetChange}
							ruleLabels={ruleLabels}
							rulePresets={rulePresets}
							inputs={inputs}
							isRunning={isRunning}
						/>
					</Col>
				</Row>
			</Form>
			<Row>
				<MatchResults isRunning={isRunning} setRunning={setRunning} inputs={inputs} matchDefaults={matchDefaults} />
			</Row>
		</>
	);

}

export default MatchSimulatorApp;