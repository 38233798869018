import React, { useEffect, useState, useRef } from 'react';
import tennisMatch from './tennis-match';
import statCollectionClass from './tennis-stats';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';

const debug = false;

export function MatchResults({ isRunning, setRunning, inputs, matchDefaults }) {
	if (!isRunning) return <></>;

	const resultRef = useRef(null);

	const executeScrollToResults = () => resultRef.current.scrollIntoView();
	
    const [matchResultArray, setMatchResultArray] = useState([]);
    const [statsTable, setStatsTable] = useState(null);

	const [renderMatchesAndResults, setRenderMatchesAndResults] = useState(false);
	var statsCombined = new statCollectionClass();

	// convert odds from inputs with fallback to defaults
	const odds = {
		firstServePercentage: [inputs.sp1_1 || matchDefaults.firstServePercentage[0], inputs.sp1_2 || matchDefaults.firstServePercentage[1]],
		winningOnFirstServe: [inputs.sw1_1 || matchDefaults.winningOnFirstServe[0], inputs.sw1_2 || matchDefaults.winningOnFirstServe[1]],
		secondServePercentage: [inputs.sp2_1 || matchDefaults.secondServePercentage[0], inputs.sp2_2 || matchDefaults.secondServePercentage[1]],
		winningOnSecondServe: [inputs.sw2_1 || matchDefaults.winningOnSecondServe[0], inputs.sw2_2 || matchDefaults.winningOnSecondServe[1]]
	}

	// convert rules from inputs
	const rules = {
		setsNeededForWin: +inputs.snfw,
		noAD: inputs.noad,
		gamesNeededForSet: +inputs.gnfs,
		normalTiebreakToPoints: +inputs.ntbtp,
		lastSetMatchTiebreak: +inputs.lsmtb,
		lastSetMatchTiebreakStartingAtGames: +inputs.lsmtbsag
	}

	// get runSpeed and amount from runCombined value
	var runAmount = 1;
	var runSpeed = 1000;
	switch(inputs.rc) {
		case 1:
			runAmount = 100;
			runSpeed = 1;
			break;
		case 2:
			runAmount = 100000;
			runSpeed = 0;
			break;
	}

    useEffect(() => {
        if (isRunning) {
            setMatchResultArray([]); 		// Clear previous results
			setStatsTable(null);
			executeScrollToResults();
			if (runAmount == 1) {			// Only one match, so run point-by-point
				const match = new tennisMatch(1, rules, odds, debug);
				setRenderMatchesAndResults(true);
				simulateNextPoint(match, 1);
			} else if (runSpeed > 0) {		// Not fastest speed
				setRenderMatchesAndResults(true);
            	simulateNextMatch(1); 		// Start simulating matches from the first match index, and start next too
			} else {						// More than one match and fastest
				for (let matchIndex = 1; matchIndex < runAmount; matchIndex++) {
					const match = new tennisMatch(matchIndex, rules, odds, debug);
					while (!match.isFinished) {
						match.playNextPoint();
					}
					for (const [key, value] of Object.entries(statsCombined.statNames)) {
						for (let p = 0; p < 2; p++) {
							statsCombined.collection[key].successes[p] += match.stats.collection[key].successes[p];
							statsCombined.collection[key].totalAmount[p] += match.stats.collection[key].totalAmount[p];
						}
					}					
				}
				setStatsTable(statsCombined.statsToTable(runAmount, false));
				setMatchResultArray([<p className="snarky-remark">Too many matches to show.</p>]);				
				setRenderMatchesAndResults(true);
			}
        }
    }, [isRunning]);

	// run next match
	const simulateNextMatch = (matchIndex) => {
        if (matchIndex > runAmount) return;

        const match = new tennisMatch(matchIndex, rules, odds, debug);
        while (!match.isFinished) {
            match.playNextPoint();
        }

        for (const [key, value] of Object.entries(statsCombined.statNames)) {
            for (let p = 0; p < 2; p++) {
                statsCombined.collection[key].successes[p] += match.stats.collection[key].successes[p];
                statsCombined.collection[key].totalAmount[p] += match.stats.collection[key].totalAmount[p];
            }
        }

        setStatsTable(statsCombined.statsToTable(matchIndex, false));

        const matchResultTable = match.resultToTable();
        setMatchResultArray(prevResults => [...prevResults, matchResultTable]);

		setTimeout(() => {
			simulateNextMatch(matchIndex + 1);
		}, runSpeed);		
	}

	// run next point
	const simulateNextPoint = (match, matchIndex) => {
        if (match.isFinished) {
			return;
		} else {
            match.playNextPoint();
			setMatchResultArray([match.resultToTable()]);
        }

        for (const [key, value] of Object.entries(statsCombined.statNames)) {
            for (let p = 0; p < 2; p++) {
				statsCombined.collection[key].successes[p] = match.stats.collection[key].successes[p];
                statsCombined.collection[key].totalAmount[p] = match.stats.collection[key].totalAmount[p];
            }
        }

        setStatsTable(statsCombined.statsToTable(matchIndex, !match.isFinished));

		setTimeout(() => {
			simulateNextPoint(match, matchIndex);
		}, runSpeed);		
	}
	
	if (renderMatchesAndResults) {
		return (
			<>
				<Col xs={12} md={6} ref={resultRef} className="mt-2 me-md-1 tennis-primary tennis-stats">
					<h2>Statistics:</h2>
						<Table responsive bordered hover>
							<tbody>
								{statsTable}
							</tbody>
						</Table>
				</Col>
				<Col className="mt-2 ms-md-1 tennis-primary">
					<h2>Results:</h2>
					{matchResultArray.map((result, index) => (
						<div key={index}>{result}</div>
					))}
				</Col>
			</>
		)
	} else {
		return (
			<>
				<Col xs={12} md={6} ref={resultRef} className="mt-2 me-md-1 tennis-primary tennis-results">
					<h2>Simulation running... Please wait</h2>
				</Col>
				<Col className="mt-2 ms-md-1 tennis-primary"></Col>
			</>
		)
	}
}
export default MatchResults;